import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layouts"
import {
  Hero,
  Breadcrumb,
  StandardContentArea,
  SeoBlock,
} from "../components/slices"

const StandardPage = ({ pageContext, uri }) => {
  const { title, seo, contentfulparent } = pageContext
  let breadcrumbData = [{ title: "Home", _meta: "/" }]

  if (pageContext.contentfulparent) {
    breadcrumbData = [
      ...breadcrumbData,
      {
        title: `${contentfulparent.title}`,
        _meta: `/${contentfulparent.slug}`,
      },
      {
        title,
        _meta: uri,
      },
    ]
  } else {
    breadcrumbData = [
      ...breadcrumbData,
      {
        title,
        _meta: uri,
      },
    ]
  }
  return (
    <Layout>
      <SeoBlock data={seo} />
      <Hero data={pageContext} className="-compact" />
      <div className="container -pinched">
        <Breadcrumb data={breadcrumbData} />
      </div>
      <div className="content standard-content">
        <div className="[ container -pinched ]">
          <StandardContentArea body={pageContext.body} />
        </div>
      </div>
      <div className="call-to-action">
        <div className="container">
          <div className="call-to-action__inner">
            <p className="[ title h3 ]">
              Kickstart your project with Solicom today
            </p>
            <Link className="button" to="/contact">
              Contact us
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default StandardPage
